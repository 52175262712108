<template>
	<uploader :filetype="this.filetype"/>
</template>

<script>
import Uploader from '../Uploader.vue';
export default {
	data(){
		return {
			filetype:"工作计划"
		}
	},
	components: { Uploader },
}
</script>